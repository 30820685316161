import {GOUVERNORATS} from '../geojson/tunisie_gouvernorats';
import {DELEGATIONS} from '../geojson/tunisie_delegations';
import {LOCALITES} from '../geojson/tunisie_localites_part_2';

function globalController($localStorage, $state, $scope, AppSettings, WS, $window, $http, $translate, $uibModal) {
    'ngInject';
    const vm = this;
    vm.actifSS = {};
    vm.global_filter = false;
    vm.settings = AppSettings;
    //vm.currentLang = AppSettings.lang;
    vm.managedObj = [];
    vm.managedObjFRS = [];
    vm.currentLang = $translate.proposedLanguage()

    vm.mapstunisie_latitude = '34.770981';
    vm.mapstunisie_longitude = '10.702299';

    //console.log(GOUVERNORATS);
    console.log('DELEGATIONS', DELEGATIONS);
    if ($localStorage.user) {
        vm.user = $localStorage.user;
    }
    if ($localStorage.dashboard) {
        vm.dashboard = $localStorage.dashboard;
    }else{
        vm.dashboard = 'vente';
    }
    if ($localStorage.role) {
        vm.role = $localStorage.role;
    }

    var now = new Date();
    var annee = now.getFullYear();
    var mois = (now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1);
    var jour = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
    var heure = now.getHours();
    var minute = now.getMinutes();
    var seconde = now.getSeconds();

    var tab_mois = new Array(
        "Janvier", "Fevrier", "Mars",
        "Avril", "Mai", "Juin",
        "Juillet", "Aout", "Septembre",
        "Octobre", "Novembre", "Decembre"
    );


    vm.aujourdhui = jour + "/" + mois;
    vm.today = now.getDate();
    vm.todayDate = moment().format('YYYY-MM-DD');
    vm.mois = tab_mois[now.getMonth()];
    vm.moissuivant = tab_mois[now.getMonth() + 1];
    vm.moisprecedent = tab_mois[now.getMonth() - 1];
    vm.month = moment().format('M');
    vm.year = moment().format('YYYY');
    vm.yearMonth = moment().format('YYYY-MM');


    WS.get('devices/currentDevise').then(
        function (response) {
            vm.currentDevise = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );


    WS.get('dashboard/echeances').then(
        function (response) {
            vm.echeances = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/factures').then(
        function (response) {
            vm.factures = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );


    WS.get('clients/nvClients').then(
        function (response) {
            vm.nvclients = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );


    WS.get('mouvements/dc').then(
        function (response) {
            vm.mouvements = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/commandes').then(
        function (response) {
            vm.commandes = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/demandesAvoirs').then(
        function (response) {
            vm.demandes = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );
    WS.get('dashboard/demande_conge').then(
        function (response) {
            vm.demande_conges = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.get('dashboard/plafond_clients').then(
        function (response) {
            vm.plafond_clients = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );



    WS.get('dashboard/credits').then(
        function (response) {
            vm.credits = response.data;
            $scope.$apply();
        },
        function (error) {
            console.log(error);
        }
    );

    WS.getScache('notes/getAllNotesNotification')
        .then(function (response) {
            vm.notesnav = response.data;
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.getScache('notes/getCountNonVu')
        .then(function (response) {
            vm.countnotesnav = response.data;
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.getScache('dashboard/visiteHorsClient')
        .then(function (response) {
            vm.visiteHorsClient = response.data;
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    WS.getScache('soussocietes/GlobalfilterSousSociete')
        .then(function (response) {
            vm.affect_ss = response.data;
          

            _.each(vm.affect_ss.all_soussocietes, (element, index) => {

                if (element.temporaire == null) {
                    element.temporaire = 0;
                    vm.affect_ss.all_soussocietes[index].temporaire = 0;
                }
                if (element.actif == null) {
                    element.actif = 0;
                    vm.affect_ss.all_soussocietes[index].actif = 0;

                }
                vm.managedObj.push({
                    soussociete_code: element.code,
                    actif: element.actif
                });
            });
            //console.log('vm.managedObj', vm.managedObj)
            $scope.$apply();


            $(function () {

                $('.button-checkbox-glob-ss').each(function () {

                    // Settings
                    var $widget = $(this),
                        $button = $widget.find('button'),
                        $checkbox = $widget.find('input:checkbox'),
                        color = $button.data('color'),
                        settings = {
                            on: {
                                icon: 'glyphicon glyphicon-check'
                            },
                            off: {
                                icon: 'glyphicon glyphicon-unchecked'
                            }
                        };

                    // Event Handlers
                    $button.on('click', function (event) {
                        $checkbox.prop('checked', !$checkbox.is(':checked'));
                        $checkbox.triggerHandler('change');

                        const id = $(this).attr('id');
                        updateDisplay(id);
                    });
                    $checkbox.on('change', function () {
                        const id = $(this).attr('id');
                        updateDisplay(id);
                    });

                    // Actions
                    function updateDisplay(id) {
                        var isChecked = $checkbox.is(':checked');
                        if (id != undefined) {
                            if (isChecked) {
                                vm.actifSS[id] = 1;
                            } else {
                                vm.actifSS[id] = 0;
                            }
                            //console.log(vm.auths[id]);
                            //console.log(vm.auths);
                        }
                        // Set the button's state
                        $button.data('state', (isChecked) ? "on" : "off");

                        // Set the button's icon
                        $button.find('.state-icon')
                            .removeClass()
                            .addClass('state-icon ' + settings[$button.data('state')].icon);

                        // Update the button's color
                        if (isChecked) {
                            $button
                                .removeClass('btn-default')
                                .addClass('btn-' + color + ' active');
                        } else {
                            $button
                                .removeClass('btn-' + color + ' active')
                                .addClass('btn-default');
                        }
                    }


                    // Initialization
                    function init() {

                        updateDisplay();

                        // Inject the icon if applicable
                        if ($button.find('.state-icon').length == 0) {
                            $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                        }
                    }
                    init();
                });
            });


        })
        .then(null, function (error) {
            console.log(error);
        });


    WS.getScache('fournisseurs/GlobalfilterFournisseur')
        .then(function (response) {
            vm.affect_frs = response.data;
            

            _.each(vm.affect_frs.all_fournisseurs, (element, index) => {

                if (element.temporaire == null) {
                    element.temporaire = 0;
                    vm.affect_frs.all_fournisseurs[index].temporaire = 0;
                }
                if (element.actif == null) {
                    element.actif = 0;
                    vm.affect_frs.all_fournisseurs[index].actif = 0;

                }
                vm.managedObjFRS.push({
                    fournisseur_code: element.code,
                    actif: element.actif
                });
            });
           
            $scope.$apply();


            $(function () {

                $('.button-checkbox-glob-frs').each(function () {

                    // Settings
                    var $widget = $(this),
                        $button = $widget.find('button'),
                        $checkbox = $widget.find('input:checkbox'),
                        color = $button.data('color'),
                        settings = {
                            on: {
                                icon: 'glyphicon glyphicon-check'
                            },
                            off: {
                                icon: 'glyphicon glyphicon-unchecked'
                            }
                        };

                    // Event Handlers
                    $button.on('click', function (event) {
                        $checkbox.prop('checked', !$checkbox.is(':checked'));
                        $checkbox.triggerHandler('change');

                        const id = 'frs_' + $(this).attr('id');
                        updateDisplay(id);
                    });
                    $checkbox.on('change', function () {
                        const id = 'frs_' + $(this).attr('id');
                        updateDisplay(id);
                    });

                    // Actions
                    function updateDisplay(id) {
                        var isChecked = $checkbox.is(':checked');
                        if (id != undefined) {
                            if (isChecked) {
                                vm.actifFRS[id] = 1;
                            } else {
                                vm.actifFRS[id] = 0;
                            }
                            //console.log(vm.auths[id]);
                            //console.log(vm.auths);
                        }
                        // Set the button's state
                        $button.data('state', (isChecked) ? "on" : "off");

                        // Set the button's icon
                        $button.find('.state-icon')
                            .removeClass()
                            .addClass('state-icon ' + settings[$button.data('state')].icon);

                        // Update the button's color
                        if (isChecked) {
                            $button
                                .removeClass('btn-default')
                                .addClass('btn-' + color + ' active');
                        } else {
                            $button
                                .removeClass('btn-' + color + ' active')
                                .addClass('btn-default');
                        }
                    }


                    // Initialization
                    function init() {

                        updateDisplay();

                        // Inject the icon if applicable
                        if ($button.find('.state-icon').length == 0) {
                            $button.prepend('<i class="state-icon ' + settings[$button.data('state')].icon + '"></i> ');
                        }
                    }
                    init();
                });
            });


        })
        .then(null, function (error) {
            console.log(error);
        });


    /**
     * check expiration compte
     */


    vm.expiration = false;
    vm.expirationErrorContrat = false;
    vm.alertExpiration = '';
    vm.support_application = null;
    vm.gestionStock = '0';
    vm.gestionCommande = '0';
    vm.merchandising = '0';
    WS.getData('societes')
        .then(function (response) {
            vm.societes = response.data[0];
            var contrat_code = vm.societes.contrat;
            /*console.log(response);
            console.log(contrat_code);*/
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/license/${contrat_code}`,
                //url: `https://api.sav.priorityexpeditions.com/api/v1/license/nomadis`,
                cache: false
            }).then(function successCallback(response) {
                //console.log($.isEmptyObject(response.data));
                //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');

                vm.support_application = response.data.support;
                vm.linkBi = response.data.url_bi;
                vm.gestionStock = response.data.gestion_stock;
                vm.global_gestion_stock = response.data.gestion_stock;
                vm.gestionCommande = response.data.gestion_commandes;
                vm.merchandising = response.data.merchandising;
                vm.url_pub = response.data.url_pub;
                vm.texte_pub = response.data.texte_pub;
                vm.debut_license = response.data.date_debut;
                vm.fin_license = response.data.date_fin;
                vm.gestionStock = '1';
                vm.global_gestion_stock = '1';
                vm.gestionCommande = '1';
                vm.merchandising = '1';

                vm.existBi = (vm.linkBi) ? true : false;
                if ($.isEmptyObject(response.data) == true) {
                    vm.expiration = true;
                    vm.expirationErrorContrat = true;
                    vm.alertExpiration = `Erreur de vérification de compte, veuillez contacter l'admin.`;
                } else {
                    //response.isactif="0";
                    if (response.data.isactif == "1") {
                        var currentday = new moment();
                        var lastday = moment(response.data.date_fin, "YYYY-MM-DD");
                        //Difference in number of days
                        var restime = moment.duration(lastday.diff(currentday)).asDays();
                        var restime = Number(restime.toFixed(0));
                        //console.log(restime);
                        //restime = 0;
                        if (restime < 7 && restime > 0) {
                            vm.expiration = true;
                            vm.alertExpiration = `Il Vous reste ${restime} jours avant l'expiration de votre compte.`;
                        }
                        if (restime <= 0) {
                            vm.expiration = true;
                            vm.alertExpiration = `Votre compte a été expiré.`;
                        }
                    }
                    //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');

                    else {
                        vm.expiration = true;
                        vm.alertExpiration = `Votre compte a été désactivé.`;
                    }
                    //console.log('-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_Contrat_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-');
                }
            }, function errorCallback(response) {
                vm.expiration = true;
                vm.expirationErrorContrat = true;
                vm.alertExpiration = `Erreur de verification de compte, veuillez contacter l'admin.`;
            });



            /**
             * get message
             */
            $http({
                method: 'GET',
                url: `https://api.sav.priorityexpeditions.com/api/v1/sendMessages/${contrat_code}`,
                //url: `http://127.0.0.1:80/clediss/gestion_projet/gestion_projet/public/api/v1/sendMessages/nomadis`,
                cache: false
            }).then(function successCallback(response) {
                // console.log('response')
                // console.log(response.data)
                // console.log('response')
                vm.message_bottom_exists = true;
                vm.background_message = response.data.background;
                vm.color_message = response.data.color;
                vm.bottom_message = response.data.message;

            }, function errorCallback(response) {
                console.log('errrooooor', response)
            });
            $scope.$apply();
        })
        .then(null, function (error) {
            console.log(error);
        });

    //var contrat_code = 'nomadis';

    vm.changeCurrentLang = () => {
        // console.log(vm.currentLang);
        $translate.use(vm.currentLang);
    };

    vm.applyFilterSS = (actif, soussociete_code) => {
        actif = (actif == 1) ? 0 : 1;
        // console.log(actif, soussociete_code);

        var obj = { soussociete_code, actif };

        vm.managedObj = _.reject(vm.managedObj, function (element) { return element.soussociete_code == soussociete_code; });

        vm.managedObj.push(obj);
        // console.log('vm.managedObj');
        // console.log(vm.managedObj);
        // console.log('--------');
    };

    vm.storeAllAffectation = () => {
        var data = {
            affectations: vm.managedObj
        }

        WS.post('soussocietes/manage_affectationSS', data).then(
            (response) => {
                if (response.data.success) {
                    toastr.success($translate.instant('affectation effectué avec succés'));
                    $window.location.reload();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.applyFilterFRS = (actif, fournisseur_code) => {
        actif = (actif == 1) ? 0 : 1;
        // console.log(actif, fournisseur_code);

        var obj = { fournisseur_code, actif };

        vm.managedObjFRS = _.reject(vm.managedObjFRS, function (element) { return element.fournisseur_code == fournisseur_code; });

        vm.managedObjFRS.push(obj);
        // console.log('vm.managedObj');
        // console.log(vm.managedObj);
        // console.log('--------');
    };

    vm.storeAllAffectationFRS = () => {
        var data = {
            affectations: vm.managedObjFRS
        }

        WS.post('fournisseurs/manage_affectationFRS', data).then(
            (response) => {
                if (response.data.success) {
                    toastr.success($translate.instant('affectation effectué avec succés'));
                    $window.location.reload();
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }


    vm.menus = [
        'POWERBI','DASHBOARD',
        'STAT-CHARGEMENTS_CHARGEMENTS', 
        'INDEX_CHARGEMENTS',
        'INDEX_CLIENTS',
        'INDEX_BL',
        'INDEX_KPI',
        'INDEX_PLANDECHARGES',
        'INDEX_GESTIONCOMMANDES',
        'INDEX_VISITES',
        'INDEX_ROUTINGS',
        'LISTUSERS_USERS',
        'INDEX_PRODUITS',
        'INDEX_RAPPORTS',
        'RAPPORTDN_ANALYSES',
        'INDEX_CHARGES',
        'INDEX_OBJECTIFS',
        'INDEX_FIDELITES',
        'INDEX_STOCKDEPOT',
        'INDEX_ACHAT',
        'INDEX_CAISSES',
        'INDEX_NOTES',
        'INDEX_CARBURANTS',
        'INDEX_KILOMETRAGES',
        'INDEX_STOCKS',
        'INDEX_RAPPORTSVISITE',
        'INDEX_CONSIGNES',
        'INDEX_INTEGRATIONERP',
        'INDEX_ASSORTIMENTS',

    ]
    WS.get('modules/get_Liste_Affichage').then(
        (response) => {
           
                vm.list_menu = response.data;

                if (vm.list_menu.length > 0) {
                    _.each(vm.menus, (element, index) => {
                        console.log(element, vm.list_menu.includes(element))
                        if(!vm.list_menu.includes(element)){
                            $('.'+element).hide();
                            $('.'+element).remove();
                            console.log(element, 'hideed')
                        }   
                    });
                }
            
        })


        $(document).on('click', function(evt) {
            clearTimeout(vm.timeOutVar );
            vm.timeOutVar = setTimeout(function(){ 
                console.log('redirect to login')
                $localStorage.$reset();
                $http.get(AppSettings.apiUrl + 'logout').then((response) => { 
                    
                    console.log(response);

                }, ((err) => {
                    console.log(err);
                }));
                $state.go('access.login', {}, { reload: true });
            }, 60*60*1000);
        });





    /**
     * ZONING
     */
    vm.openModalZonning =() => {
        vm.modal_zonning = $uibModal.open({
            animation: true,
            templateUrl: 'modalZonning',
            size: 'lg',
            backdrop: true,
            windowClass : 'modal-zonning',
            controller: function($scope, $translate) {

                $scope.stock_all_layers = [];
                $scope.layers_json = [];
                $scope.layers_params = [];

                $scope.closeZonning = () => {
                    console.log('panel_closed')
                    vm.modal_zonning.close();
                }

                $scope.current_gouvernorat = false;

                $scope.selected_gouvernorat = [];

                $scope.selectGouvernorats = () => {
                    $scope.current_gouvernorat = false;
                    WS.getData('gouvernorats')
                        .then(function(response) {
                            if (response.status == 200) {
                                $scope.gouvernorats = response.data;
                                $scope.initMapsGouvernorats();
                                if($scope.current_gouvernorat_element){
                                    $scope.toggleStreet($scope.current_gouvernorat_element, null, false);
                                }
                                $scope.$apply();
                            } else {}
                        })
                        .then(null, function(error) {
                            console.log(error);
                        });
                }


                $scope.selectDelegationByGouvernorat = () => {
                    $scope.selected_gouvernorat = [];
                    WS.getData('delegations/selectDelegationByGouvernorat', {gouvernorat_code : $scope.gouvernorat_code })
                        .then(function(response) {
                            if (response.status == 200) {
                                $scope.delegations = response.data;
                                $scope.selected_gouvernorat =  _.filter($scope.gouvernorats, function(gouv){ return gouv.code == $scope.gouvernorat_code; });
                                $scope.$apply();

                                $scope.initMapsDelegations();
                            } else {}
                        })
                        .then(null, function(error) {
                            console.log(error);
                        });
                }

                $scope.initMapsDelegations = ( ) => {
                    console.log('init initMapsDelegations')
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_delegations').empty();
                    $('#container_delegations').append('<div style="width: 100%; height: 600px" id="maps_delegations"></div>');
            
                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_delegations', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });
            
                    $scope.osm.addTo($scope.map);
            
                    //current gouvernorat
                    _.each($scope.selected_gouvernorat, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                       // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })
                    //add poligones : 
                    _.each($scope.delegations, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#e50202';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                       // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })

                    

                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }


                

                $scope.correctionGouvernorats = () => {
                    console.log(GOUVERNORATS.features);


                    WS.post('gouvernorats/correctionGouvernorats', { data : GOUVERNORATS.features })
                        .then(function(response) {
                            if (response.status == 200) {
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('Action effectué avec succès'),
                                    'success'
                                )
                                $scope.selectGouvernorats();
                                $scope.$apply();
                            } else {}
                        })
                        .then(null, function(error) {
                            console.log(error);
                        });
                }



                $scope.correctionDelegations = () => {
                    console.log(DELEGATIONS.features);

                    _.each(DELEGATIONS.features, (element,index) => {
                        WS.post('delegations/correctionDelegations', { data : element })
                        .then(function(response) {
                            if (response.status == 200) {
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('Action effectué avec succès'),
                                    'success'
                                )
                               // $scope.selectDelegationByGouvernorat();
                                $scope.$apply();
                            } else {}
                        })
                        .then(null, function(error) {
                            console.log(error);
                        });
                    })
                    
                }



                $scope.correctionLocalites = () => {
                    console.log(LOCALITES.features);

                    _.each(LOCALITES.features, (element,index) => {
                        WS.post('localites/correctionLocalites', { data : element })
                        .then(function(response) {
                            if (response.status == 200) {
                                swal(
                                    $translate.instant('Succès'),
                                    $translate.instant('Action effectué avec succès'),
                                    'success'
                                )
                               // $scope.selectDelegationByGouvernorat();
                                $scope.$apply();
                            } else {}
                        })
                        .then(null, function(error) {
                            console.log(error);
                        });
                    })
                    
                }


                $scope.selectLocalitesBySearch = () => {
                    $scope.selected_gouvernorat = [];
                    WS.getData('localites/selectLocalitesBySearch', {gouvernorat_code : $scope.gouvernorat_code, delegation_code: $scope.delegation_code })
                        .then(function(response) {
                            if (response.status == 200) {
                                $scope.localites = response.data;
                                $scope.selected_gouvernorat =  _.filter($scope.gouvernorats, function(gouv){ return gouv.code == $scope.gouvernorat_code; });
                                $scope.selected_delegation =  _.filter($scope.delegations, function(del){ return del.code == $scope.delegation_code; });
                                $scope.$apply();

                                $scope.initMapsLocalites();
                            } else {}
                        })
                        .then(null, function(error) {
                            console.log(error);
                        });
                }

                $scope.initMapsLocalites = ( ) => {
                    console.log('init initMapsLocalites')
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_localites').empty();
                    $('#container_localites').append('<div style="width: 100%; height: 600px" id="maps_localites"></div>');
            
                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_localites', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });
            
                    $scope.osm.addTo($scope.map);
            
                    //current delegation
                    _.each($scope.selected_gouvernorat, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                       // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })

                    //current delegation
                    _.each($scope.selected_delegation, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#e50202';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                       // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })
                    //add poligones : localites
                    _.each($scope.localites, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : 'green';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                       // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })

                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }

                $scope.initMapsGouvernorats = ( ) => {
                    console.log('init initMapsGouvernorats')
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
                    $scope.center_latitude = vm.mapstunisie_latitude;
                    $scope.center_longitude = vm.mapstunisie_longitude;
                    $('#container_gouvernorats').empty();
                    $('#container_gouvernorats').append('<div style="width: 100%; height: 600px" id="maps_gouvernorats"></div>');
            
                    $scope.osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
                    $scope.osmAttrib = '&copy; <a href="http://nomadis.online/">Nomadis</a>';
                    $scope.osm = L.tileLayer($scope.osmUrl, {
                        maxZoom: 18,
                        attribution: $scope.osmAttrib
                    });
                    //console.log('from maps : ', $scope.getpays)
                    $scope.map = new L.Map('maps_gouvernorats', {
                        center: new L.LatLng($scope.center_latitude, $scope.center_longitude),
                        zoom: 11,
                        fullscreenControl: true,
                        fullscreenControlOptions: {
                            position: 'topright'
                        }
                    });
            
                    $scope.osm.addTo($scope.map);
            
                    //add poligones : 
                    _.each($scope.gouvernorats, (element, index) => {
                        $scope.paramsdrawitem = {};
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                            //layer._leaflet_id = element.id;
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                       // $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })
                    $scope.map.fitBounds($scope.bounds);
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('initialized');
                    }, 3000);
                }



                $scope.clearLayers = () => {
                    $scope.current_gouvernorat = false;
                    _.each($scope.stock_all_layers, layer => {
                        $scope.map.removeLayer(layer)
                    });
                    setTimeout(function () {
                        $scope.map.invalidateSize();
                        console.log('clearLayers initialized');
                    }, 2000);
                }
            
                $scope.appendLayers = () => {
                    $scope.current_gouvernorat = false;
                    $scope.clearLayers();
            
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
            
                    _.each($scope.gouvernorats, (element, index) => {
                        $scope.paramsdrawitem.id = element.id;
                        $scope.paramsdrawitem.code = element.code;
                        $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                        $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                            layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle + '</pre>');
                        }
                        $scope.current_layer = JSON.parse(element.layer);
                        $scope.current_layer._leaflet_id = element.id;
                        $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem).addTo($scope.map);
                        $scope.stock_all_layers.push($scope.drawnItems);
                        $scope.layers_json.push($scope.current_layer);
                        $scope.layers_params.push($scope.paramsdrawitem);
                        
                        
                        if(index==0){
                            $scope.bounds = L.latLngBounds($scope.drawnItems.getBounds());
                        }else{
                            $scope.bounds.extend($scope.drawnItems.getBounds());
                        }
                    })
                    $scope.map.fitBounds($scope.bounds);
                    //reinitialise draw for create new tournee
                    $scope.drawnItems = L.geoJson(null, $scope.paramsdrawitem).addTo($scope.map);
                }
            

                /**
                 * 
                 * @param {*} element : gouvernorat obj
                 * @param {*} key 
                 */
                $scope.toggleStreet = (element, key = null, speak=true) => {
                    if(speak){
                        $window.speechSynthesis.speak(new SpeechSynthesisUtterance(element.libelle));
                    }
                    $scope.clearLayers();
                    $scope.current_gouvernorat = true;
                    $scope.current_gouvernorat_element = element;
                    $scope.current_gouvernorat_libelle = element.libelle;
                    
                    $scope.stock_all_layers = [];
                    $scope.layers_json = [];
                    $scope.layers_params = [];
            
                    $scope.paramsdrawitem.id = element.id;
                    $scope.paramsdrawitem.code = element.code;
                    $scope.paramsdrawitem.color = (element.couleur) ? element.couleur : '#3388ff';
                    $scope.paramsdrawitem.onEachFeature =  function (feature, layer) {
                        layer.bindPopup('<pre>'+ element.code + ' - '+ element.libelle  +'</pre>');
                       // layer._leaflet_id = element.id;
                    }
                    $scope.current_layer = JSON.parse(element.layer);
                   // $scope.current_layer._leaflet_id = element.id;
                    $scope.drawnItems = L.geoJson($scope.current_layer, $scope.paramsdrawitem, element.type_layer, element.id).addTo($scope.map);
                    $scope.stock_all_layers.push($scope.drawnItems);
                    $scope.layers_json.push($scope.current_layer);
                    $scope.layers_params.push($scope.paramsdrawitem);
            
                    $scope.map.fitBounds($scope.drawnItems.getBounds());

                    // WS.getData('gouvernorats/getDetailsGouvernorat', {gouvernorat_code: element.code})
                    //     .then(function(response) {
                    //         if (response.status == 200) {
                    //             $scope.details_gouvernorats = response.data;                                
                    //             $scope.$apply();
                    //         } else {}
                    //     })
                    //     .then(null, function(error) {
                    //         console.log(error);
                    //     });
                }

                
            }
        });
    }


    
        
}
export default {
    name: 'globalController',
    fn: globalController
};
